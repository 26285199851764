.patient-form{
  width: 100%;
  padding: .5em;
  .form-section{
    width: 100%;
    background-color: #f2f2f2;
    padding: .5em;
    margin-top: 2em;
    .form-section-title{
      width: 100%;
    }
    .form-section-inputs{
      width: 100%;
      margin-top: 1em;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

  }
}

.form-control{
  display: block;
  font-size: 15px;
  margin-top: 1em;
  .form-control-label{
    display: block;
  }
  .form-control-field{
    display: block;
    margin-top: .5em;
    height: 40px;
    width: 280px;
    box-sizing: border-box;
    padding: .1em .5em;
    outline: none;

  }
  .radio-group{
    margin-top: .5em;
    display: block;
    display: flex;
    justify-content: space-around;
  }
  
}
.row{
  width: 100%;
}
.pt-1{
  padding-top: 1em;
}