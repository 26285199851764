.wrapper{
  width: 100%;
  min-height: 200px;
  margin: auto;
  padding: 2em;
  box-sizing: border-box;
  .tables-tabs{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .tab-item{
      height: 100%;
      width: 200px;
      background-color: #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
     
    }
  }
  .tables-content{
    width: 100%;
    height: 40px;
    background-color: rebeccapurple;
    position: relative;
    top: 1em;
  }
}